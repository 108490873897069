import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/root/Layout"
import Seo from "../../components/root/Seo"
export default function Mission() {
  return (
    <Layout>
      <Seo title="Our Mission" />
      <div className="container py-8 md:py-16 lg:py-24">
        <h1 className="text-2xl md:text-4xl font-bold">
          ArchUp’s mission is to{" "}
          <span className="text-brand-blue">
            empower students and young professionals
          </span>{" "}
          to navigate the world of work. We are dedicated to providing{" "}
          <span className="text-brand-blue">
            high-quality, structured mentoring and training
          </span>{" "}
          for the development of cross-industry, professional competencies.
          <br />
          <br />
          Collectively, we are facilitating a culture of inclusivity and
          diversity in the workplace for a better future.{" "}
        </h1>
      </div>
      <div className="bg-brand-blue-light text-white">
        <div className="container py-8 md:py-16 lg:py-24">
          <h1 className="text-3xl md:text-5xl leading-tight font-bold mb-3">
            Are you with us?
          </h1>
          <p className="mb-8 text-2xl">Help us create a better future.</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
              <Link to="/mentees" className="btn-secondary text-center">Find a Mentor</Link>
              <Link to="/mentors" className="btn-secondary text-center">Become a Mentor</Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
